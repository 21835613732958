import React from 'react'
import { FieldProps } from 'formik'
import { defaultCountries, usePhoneInput } from 'react-international-phone'
import 'react-international-phone/style.css'

import { TextInputFieldV2 as TextInputField } from 'components/form'

const MobilePhoneInput = (props: FieldProps) => {
  const { inputValue, handlePhoneValueChange } = usePhoneInput({
    defaultCountry: 'kz',
    value: props.field.value,
    countries: defaultCountries,
    onChange: (data) => {
      props.form.setFieldValue(props.field.name, data.phone)
    },
  })

  const [isFocused, setFocusState] = React.useState(false)

  const handleFocus = () => {
    setFocusState(true)
  }

  const handleBlur = () => {
    setFocusState(false)
  }

  return (
    <TextInputField
      {...props}
      value={inputValue}
      onChange={handlePhoneValueChange}
      forceFloat={isFocused || Boolean(inputValue)}
      onFocus={handleFocus}
      onBlur={handleBlur}
    />
  )
}

export default MobilePhoneInput
