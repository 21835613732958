// @flow
import * as yup from 'yup'
import pick from 'lodash/pick'

import type { FormikProps } from 'formik'

import { composeRegex } from './misc'
import strings from '../localization'
import { DELIVERY_TYPE } from './api'

yup.setLocale(strings.yup)

const rePhoneNumber =
  /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/

yup.addMethod(yup.string, 'phone', function () {
  return this.test('phone', strings.validation.phoneFormat, (value) =>
    rePhoneNumber.test(value)
  )
})

export const loginFormValidation = yup.object({
  email: yup.string().label(strings.loginPage.form.email).email().required(),
  password: yup
    .string()
    .label(strings.loginPage.form.password)
    .min(6)
    .required(),
})

const registrationFormFields = {
  firstName: yup
    .string()
    .label(strings.registrationPage.form.firstName)
    .required(),
  lastName: yup
    .string()
    .label(strings.registrationPage.form.lastName)
    .required(),
  phone: yup
    .string()
    .label(strings.registrationPage.form.phone)
    .phone()
    .required(),
  email: yup
    .string()
    .label(strings.registrationPage.form.email)
    .email()
    .required(),
  password: yup
    .string()
    .label(strings.registrationPage.form.password)
    .min(6)
    .required(),
  passwordConfirmation: yup
    .string()
    .label(strings.registrationPage.form.passwordConfirmation)
    .oneOf(
      [yup.ref('password'), null],
      strings.validation.passwordsDoesNotMatch
    )
    .required(),
}

export const mobileRegistrationFormFirstPart = yup.object({
  ...pick(registrationFormFields, ['firstName', 'lastName', 'phone']),
})

export const mobileRegistrationFormSecondPart = yup.object({
  ...pick(registrationFormFields, [
    'email',
    'password',
    'passwordConfirmation',
  ]),
})

export const registrationFormValidation = yup.object({
  ...registrationFormFields,
})

export const userSettingsFormValidation = yup.object({
  firstName: yup
    .string()
    .label(strings.userSettingsForm.personalData.firstName)
    .required(),
  lastName: yup
    .string()
    .label(strings.userSettingsForm.personalData.lastName)
    .required(),
  email: yup
    .string()
    .label(strings.userSettingsForm.personalData.email)
    .email()
    .required(),
  phone: yup
    .string()
    .label(strings.userSettingsForm.personalData.phone)
    .phone()
    .required(),
  password: yup
    .string()
    .label(strings.userSettingsForm.password.newPassword)
    .min(6),
  passwordConfirmation: yup
    .string()
    .label(strings.userSettingsForm.password.newPasswordConfirmation)
    .oneOf(
      [yup.ref('password'), null],
      strings.validation.passwordsDoesNotMatch
    ),
  // TODO: Require passwordConfirmation to be present when password is
})

export const forgotPasswordFormValidation = yup.object({
  email: yup
    .string()
    .label(strings.forgotPasswordPage.form.email)
    .email()
    .required(),
})

const trackNumberRejectRegex = composeRegex([
  // links
  /:\/\/.*/,

  // cyrillic letters
  /[\u0401\u0451\u0410-\u044f]/,

  // WAxxxxxxxxx
  /^WA.*/,

  // Wxxxxxxxxx (Apple.com order number)
  /^W.*/,

  // GS.xxxx-xxxx-xxxx (Google Store order number)
  /^GS.*/,

  // CARxxxxxxxx (Carter's order number)
  /^CAR.*/,

  // xx-xxxxx-xxxxx (eBay order number)
  /^\d{2}-.*/,

  // xxx-xxxxxxxx
  /^\d{3}-.*/,

  // whitespace char
  /.*\s.*/,
])

export const trackingNumber = yup
  .string()
  .required()
  .min(6)
  .trim()
  .test(
    'not-restricted-pattern',
    '${path} имеет неправильный формат',
    (value) => typeof value === 'string' && !value.match(trackNumberRejectRegex)
  )
  .label(strings.addParcelForm.form.trackingNumber)

export const parcelDescriptionFormat = yup
  .string()
  .required()
  .trim()
  .test(
    'mixed-cyrillic-latin-letters',
    'Тип товара укажите на русском, а бренд на английском',
    (value) =>
      typeof value === 'string' &&
      value.match(/[а-яА-ЯЁё]/) &&
      value.match(/[a-zA-Z]/)
  )
  .label(strings.addParcelForm.form.description)

const commonParcelFormFields = {
  description: parcelDescriptionFormat,
  parcelPrice: yup
    .number()
    .nullable()
    .min(0.001)
    .max(99999)
    .label(strings.addParcelForm.form.price)
    .required(),
}

export const addParcelFormValidation = [
  yup.object({
    trackingNumber,
    ...commonParcelFormFields,
  }),
  yup.object({
    address: yup
      .string()
      .nullable()
      .label(strings.addParcelForm.form.address)
      .when('deliveryType', {
        is: (deliveryType) => deliveryType === DELIVERY_TYPE.DELIVERY,
        then: yup.string().label(strings.addParcelForm.form.address).required(),
      }),
    recipientId: yup
      .number()
      .label(strings.addParcelForm.form.recipient)
      .required(),
  }),
]

export const editParcelFormSchema = yup.object({
  ...commonParcelFormFields,
  address: yup.string().nullable().label(strings.dashboardParcels.item.address),
  recipientId: yup
    .number()
    .label(strings.addParcelForm.form.recipient)
    .required(),
})

export function validateSearchForm(values: Object) {
  let errors = {}

  if (!values.email) {
    errors.email = 'Required'
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'Invalid email address'
  }

  if (!values.text) {
    errors.text = 'Required'
  }

  return errors
}

export function fieldHasError(
  fieldName: string,
  formProps: FormikProps<*>,
  forceErrorVisibility: boolean = false
) {
  const hasError = formProps?.errors?.[fieldName]

  if (forceErrorVisibility) return hasError

  if (formProps?.submitCount === 0) return false

  return hasError
}

export const courierShippingFormValidation = yup.object({
  origin_id: yup.string().label('Откуда').required(),
  destination_id: yup.string().label('Куда').required(),
  weight: yup.number().nullable().min(0.001).max(500).label('Вес').required(),
})

export const recipientFormValidation = yup.object({
  phone: yup
    .string()
    .label(strings.userSettingsForm.personalData.phone)
    .phone()
    .required(),
  tin: yup.string().nullable().label(strings.recipientForm.tin).required(),
  passport_fullname: yup
    .string()
    .nullable()
    .label(strings.recipientForm.passportSection.passportFullname)
    .required(),
  passport_number: yup
    .number()
    .label(strings.recipientForm.passportSection.passportNumber)
    .required(),
  passport_issued_by: yup
    .string()
    .label(strings.recipientForm.passportSection.passportIssuedBy)
    .required(),
  passport_issued_at: yup
    .date()
    .label(strings.recipientForm.passportSection.passportIssuedAt)
    .required(),
  passport_expires_at: yup
    .date()
    .label(strings.recipientForm.passportSection.passportExpiresAt)
    .required(),
  city_id: yup
    .number()
    .label(strings.recipientForm.addressSection.city)
    .required(),
  delivery_address: yup
    .string()
    .label(strings.recipientForm.addressSection.address)
    .required(),
})
